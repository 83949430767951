
import { Common } from '@/common'
import { Clinic } from '@/modules/clinic'
import { System } from '@/modules/system'
import $app from '@/plugins/modules'
import { Component, Vue } from 'vue-property-decorator'
import { IFeeGrid } from '../types'

@Component
export default class FeesView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  clinic = $app.module(Clinic);

  fees: Array<IFeeGrid> = [];
  adding = false;
  storing = false;
  tab = 0;

  async load() {
    try {
      const fees: Array<IFeeGrid> = await $app.get('/api/clinics/fees');
      this.fees = fees;
      this.tab = 1;
    } catch (err) {
      $app.pushError(err);
      throw err;
    }
  }

  async store(grid: IFeeGrid) {
    this.storing = true;
    try {
      await $app.post('/api/clinics/fees', grid);
    } catch (error) {
      $app.pushError(error);
    }
    this.storing = false;
  }

  async remove(grid: IFeeGrid) {
    this.storing = true;
    try {
      await $app.delete('/api/clinics/fees/' + grid.id);
      await this.load();
    } catch (error) {
      $app.pushError(error);
    }
    this.storing = false;
  }

  async append() {
    this.adding = true;
    try {
      const grid: IFeeGrid = {
        id: null,
        name: 'NEW PLACE',
        onboarding: 0,
        salary: 0
      }
      await $app.post('/api/clinics/fees', grid);
      await this.load();
    } catch (error) {
      $app.pushError(error);
    }
    this.adding = false;
  }

  created() {
    this.load();
  }
}
